import { payTypeCodes } from "../../constants/quote-status.constants";
import { appTypes, NO } from "../constants/config.constants";
import isEmpty from "lodash/isEmpty";

/**
 * Message utility
 *
 * @param {object} errors
 * @param {object} service
 * @returns string
 */
const getWarningMessages = (errors, service) => {
  return Object.keys(errors)
    .filter(error => errors[error])
    .map(error => {
      if (error === "payTypes") {
        return `The service ${
          service.name
        } can't be added without paytype for dealer ${service.dealer} ${
          service.confirmationId ? `, quote ${service.confirmationId}` : ""
        }`;
      }

      return null;
    })
    .join(", ")
    .replace(/,$/, ".");
};

/**
 * Generates an array of pay type options with a value and a label.
 *
 * @param {Array<{ dealerPayTypeId: number, payCode: string, description: string }>} payTypes - The array of pay types to process.
 * @param {string} appType - Current appType { SQ, CSR }
 * @returns {Array<{ value: string, label: string }>} An array of objects each with a value and label representing a pay type.
 */
const buildPayTypeOption = (
  payTypes,
  appType = "",
  isMenu = false,
  isDmsPlusDealer = false
) => {
  const isCSR = appType === appTypes.CSR;

  if (isEmpty(payTypes)) {
    return [];
  }

  const filteredPayTypes = payTypes.filter(payType => {
    if (isCSR) {
      if (isMenu) {
        return payType.payCode !== payTypeCodes.SERVICE_CONTRACT;
      }
    }
    return true;
  });

  const mappedPayTypes = filteredPayTypes.map(
    ({ dealerPayTypeId, payCode }) => ({
      value: dealerPayTypeId.toString(),
      label: payCode
    })
  );

  if (isMenu && isDmsPlusDealer) {
    return mappedPayTypes.filter(payType => payType.label !== "W");
  }

  return mappedPayTypes;
};

/**
 * This function return parts with { selected: true } property
 * @param {*} parts
 * @returns
 */
const filterPartsBySelected = (parts = []) =>
  parts?.filter(part => part.selected);

const isDMSPlus = dmsType => dmsType?.toUpperCase() === "DMS+";
const isDTDMS = dealerProperties =>
  (!dealerProperties?.ENABLE_CSR_APP ||
    dealerProperties?.ENABLE_CSR_APP === NO) &&
  (!dealerProperties?.SPI_SQS_ENABLED ||
    dealerProperties?.SPI_SQS_ENABLED === NO);

/**
 * Builds an array of service type options based on the provided list.
 *
 * @param {Array} serviceTypesList - The list of service types to be processed.
 * @returns {Array} An array of objects representing service type options with 'value' and 'label' properties.
 */
const buildServiceTypeOption = (serviceTypesList = []) => {
  /**
   * Checks if the given array is empty.
   *
   * @param {Array} arr - The array to be checked.
   * @returns {boolean} True if the array is empty, false otherwise.
   */
  const isEmpty = arr => arr.length === 0;

  return (
    (!isEmpty(serviceTypesList) &&
      serviceTypesList.map(serviceType => ({
        value: serviceType.dealerServiceTypeId.toString(),
        label: serviceType.serviceTypeCode
      }))) ||
    []
  );
};

export {
  getWarningMessages,
  buildPayTypeOption,
  filterPartsBySelected,
  isDMSPlus,
  buildServiceTypeOption,
  isDTDMS
};
