import React, { useCallback, useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
// import ServiceList from "../../components/service-list/ServiceList";
import "./DeclinedServicesQuickFilterPage.scss";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import EditDeclinedServicePage from "./EditDeclinedServicePage";
import ServicesList from "../ServiceList/services-list.component";
import serviceDataMapper from "../../utils/service-data-mapper";
import { GlobalOpsServiceType } from "../../constants/search.constants";
import selectServiceFormat from "../../utils/select-service-format";
import { isDTDMS } from "../../utils/service.util";

const DeclinedServicesQuickFilterPage = props => {
  const { EditServiceModule } = props;
  const { state, dispatch, ctxGtmEvent, ctxRequestOperationDetails } =
    useServiceSearchContext();
  const {
    declinedServices,
    globalOperationDetails,
    appConfig,
    vehicle,
    dealerProperties
  } = state;
  const isDtdmsDealer = isDTDMS(dealerProperties);

  const [showEditCmp, setShowEditCmp] = useState(false);
  const [declinedAPIService, setDeclinedAPIService] = useState(null);
  const [formattedService, setFormattedService] = useState(null);
  const [saveFromExtApp, setSaveFromExtApp] = useState(false);

  const addSelectedDeclinedServiceFromExtApp = useCallback(
    async (declinedService, serviceComments, asrNotes, duration, opCode) => {
      if (declinedService && Object.keys(declinedService).length > 0) {
        const rawService = JSON.stringify(declinedService);
        const declinedServiceObj = {
          ...declinedService,
          serviceComments,
          asrNotes,
          durationMins: duration,
          dmsOpcode: opCode,
          quoteRawService: { rawService }
        };
        dispatch({
          type: Actions.SET_SELECTED_DECLINED_SERVICE,
          payload: declinedServiceObj
        });
      }
    },
    [dispatch]
  );

  const handleSaveDeclinedServiceFromExtApp = useCallback(
    (serviceComments, originalNotes, duration, opCode) => {
      addSelectedDeclinedServiceFromExtApp(
        declinedAPIService,
        serviceComments,
        originalNotes,
        duration,
        opCode
      );
    },
    [declinedAPIService, addSelectedDeclinedServiceFromExtApp]
  );

  useEffect(() => {
    // Saves the declined service when parent application is not QUOTING and
    // the user is not taken to EditDeclinedServicePage where saving would be normally done.
    if (!isEmpty(declinedAPIService) && saveFromExtApp) {
      const emptyComments = "";
      const originalNotes =
        declinedAPIService.notes && declinedAPIService.notes.length > 0
          ? declinedAPIService.notes
          : "";
      const duration =
        declinedAPIService.duration && declinedAPIService.duration > 0
          ? declinedAPIService.duration
          : 0;
      const opCode =
        declinedAPIService.opCode && declinedAPIService.opCode.length > 0
          ? declinedAPIService.opCode
          : "";
      handleSaveDeclinedServiceFromExtApp(
        emptyComments,
        originalNotes,
        duration,
        opCode
      );
    }
  }, [saveFromExtApp, declinedAPIService, handleSaveDeclinedServiceFromExtApp]);

  const handleServiceClick = useCallback(
    service => {
      ctxGtmEvent?.trackGAEventWithParam("ga.newquote.select_service_click", {
        result: service.operationSource,
        location: "declined-quick-filter"
      });
      if (
        !isDtdmsDealer &&
        service?.operationId &&
        !isEmpty(service?.catalogOperationSource)
      ) {
        ctxRequestOperationDetails(service);
      } else {
        setFormattedService(service);
      }
      setDeclinedAPIService(service);
      if (appConfig.editModuleAccess) {
        setShowEditCmp(true);
        dispatch({
          type: Actions.SET_SEARCH_HEADER,
          payload: false
        });
      } else {
        setSaveFromExtApp(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, appConfig.editModuleAccess]
  );

  const updateParentState = showEdit => {
    if (!showEdit) {
      setDeclinedAPIService(null);
      setFormattedService(null);
      dispatch({
        type: Actions.REMOVE_GLOBAL_OPERATION_DETAILS
      });
    }
    setShowEditCmp(showEdit);
  };

  const updateParentService = useCallback(service => {
    if (!isEmpty(service)) {
      setFormattedService(service);
    }
  }, []);

  const editServiceClick = useCallback(
    service => {
      updateParentService(service);
      updateParentState(true);
      dispatch({
        type: Actions.SET_SEARCH_HEADER,
        payload: false
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const editPage =
    showEditCmp && !isEmpty(formattedService) ? (
      <EditDeclinedServicePage
        editServiceClick={editServiceClick}
        updateParentState={updateParentState}
        service={formattedService}
        EditServiceModule={EditServiceModule}
      />
    ) : (
      ""
    );

  const parentCls =
    showEditCmp && !isEmpty(declinedAPIService) ? "hide-ele" : "empty-cls";

  useEffect(() => {
    if (isDtdmsDealer && !isEmpty(declinedAPIService)) {
      setFormattedService(declinedAPIService);

      return;
    }

    // We need this check because globalOperationDetails will not be available in context until ctxRequestOperationDetails is triggered
    // by handleDiagnosisClick below. This setup requires service in local state, so we also need to check for it.
    if (!isEmpty(declinedAPIService) && !isEmpty(globalOperationDetails)) {
      const selectedService = selectServiceFormat.formatService(
        declinedAPIService,
        globalOperationDetails
      );
      if (!isEmpty(vehicle?.stockNumber)) {
        selectedService.defaultPayTypeCode = "I";
      }
      const { editModuleAccess } = appConfig;
      if (editModuleAccess) {
        const { rawRecord } = declinedAPIService;
        if (rawRecord.operationSource) {
          editServiceClick(selectedService);
        }
      } else {
        // If parent application is not QUOTING, just dispatch selectedService
        dispatch({
          type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
          payload: selectedService
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    declinedAPIService,
    globalOperationDetails,
    editServiceClick,
    dispatch,
    appConfig
  ]);

  return (
    <>
      {/* <div id="declinedServicesQuickFilterPageContainer" className={parentCls}>
        <ServiceList
          services={declinedServices}
          viewType="DECLINED"
          onSelectService={handleServiceClick}
        />
      </div> */}

      <ServicesList
        parentCls={parentCls}
        services={declinedServices}
        viewType="DECLINED"
        onSelectService={handleServiceClick}
      />
      {editPage}
    </>
  );
};

export default DeclinedServicesQuickFilterPage;
