/* eslint-disable no-console */
import xmmAxios from "./xmmAxios";
import {
  appSources,
  appTypes,
  appSourceCodes,
  APPLICATIONS
} from "../constants/app.constants";
import { NO } from "../features/repair-order/constants/adjustment.constant";

// check appSource code to redirect access page
export function checkSourceCode(code) {
  const found = appSourceCodes.find(
    element => typeof element === "string" && element === code
  );
  return !found ? false : true;
}

/**
 * This function returns individual parameter value for a key used in window query str
 * @param {string} key
 * @returns
 */
export function getUrlParameter(key) {
  // eslint-disable-next-line no-useless-escape
  const name = key.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
/**
 * This util used to convert query strings to readable json object
 * @returns {object} baseParams
 */
export function fromQueryString() {
  const baseParams = {};
  baseParams.webKey = getUrlParameter("webKey");
  baseParams.appSource = getUrlParameter("appSource");
  baseParams.userName = getUrlParameter("userName");
  baseParams.locale = getUrlParameter("locale");
  baseParams.dealerCode = "";
  baseParams.customerId = getUrlParameter("customerId");
  baseParams.vehicleId = getUrlParameter("vehicleId");
  baseParams.quoteId = getUrlParameter("quoteId");
  baseParams.debugMode = getUrlParameter("debugMode");
  // @appType now applies to CSR and SQ both
  baseParams.appType = getUrlParameter("appType");
  // @CSR Logic
  baseParams.csrNumber = getUrlParameter("csrNumber");
  baseParams.act = getUrlParameter("act");
  baseParams.view = getUrlParameter("view");
  return baseParams;
}
/**
 * This util used to convert query strings to readable json object
 * eg: http://test.com/?simple1=nospace&not_simple=with%20spaces%20and%20characters
 * */
export function queryStringToJSON() {
  const result = {};
  const search = window.location.search;
  if (search) {
    const pairs = window.location.search.slice(1).split("&");
    pairs.forEach(function (pair) {
      const pairArr = pair.split("=");
      result[pairArr[0]] = decodeURIComponent(pairArr[1] || "");
    });
    return JSON.parse(JSON.stringify(result));
  } else {
    return null;
  }
}

/**
 * Utils convert json object to query string
 *  @param {Object} params The object to encode
 *  @return {String} queryString
 * input: 
 * var params = {
    a: 1,
    b: 2,
    c: 3
    }; 
  output: "a=1&b=2&c=3"
 * */

export function toQueryString(params) {
  const queryString = Object.keys(params)
    .map(key => key + "=" + params[key])
    .join("&");
  return queryString;
}

export function objectToQuerystring(obj) {
  return Object.keys.reduce(function (str, key, i) {
    const delimiter = i === 0 ? "?" : "&";
    const keyProp = encodeURIComponent(key);
    const val = encodeURIComponent(obj[key]);
    return [str, delimiter, keyProp, "=", val].join("");
  }, "");
}

// * Important: remove XT login params and redirect to CSR portal URL
export function refineURLForCSR() {
  const baseParams = queryStringToJSON();
  if (baseParams) {
    if (baseParams.hasOwnProperty("pt")) delete baseParams.pt;
    if (baseParams.hasOwnProperty("sqenv")) delete baseParams.sqenv;
    if (baseParams.hasOwnProperty("xs")) delete baseParams.xs;
    if (baseParams.hasOwnProperty("w")) {
      baseParams.webKey = baseParams.w;
      delete baseParams.w;
    }
  }
  return toQueryString(baseParams);
}

export function refineURL() {
  const baseParams = queryStringToJSON();
  if (baseParams) {
    if (baseParams.hasOwnProperty("pt")) delete baseParams.pt;
    if (baseParams.hasOwnProperty("sqenv")) delete baseParams.sqenv;
    if (baseParams.hasOwnProperty("xs")) delete baseParams.xs;
  }
  return toQueryString(baseParams);
}

// Note: This util is called at first place in App.js
export function getAppEnvironment() {
  const { hostname } = window.location;
  const host = hostname.includes("localhost")
    ? "servicequoting.dev6.xtime.app.coxautoinc.com"
    : hostname;
  const hostArr = host.split(".");
  let quoteEnv = hostArr[1];
  // TODO: Must check when quoting deployred in AUS prod?
  quoteEnv = quoteEnv.includes("xtime") ? "prod" : quoteEnv;
  // console.log("getAppEnvironment", host, quoteEnv);
  let returnedEnv = "qa5";
  switch (quoteEnv) {
    case "dev6":
      returnedEnv = "qa5";
      break;
    case "qa6":
      returnedEnv = "ua9";
      break;
    case "demo":
    case "pt3":
      returnedEnv = "pt3";
      break;
    case "stg4":
      returnedEnv = "stg4";
      break;
    case "prod":
      returnedEnv = "prod";
      break;
    default:
      returnedEnv = "qa5";
  }
  return returnedEnv;
}
// method check if quoting is standalone or not
export function checkIfStandaloneApp(appSource) {
  return appSource === appSources.IND;
}

export function checkIfIsCSR(appType) {
  return appType === appTypes.CSR;
}
// @csr-logic
export function redirectToCSRPage(appSource, appType, quote) {
  const { quoteId } = quote;
  let page = null;
  if (appType === appTypes.CSR) {
    if (appSource === appSources.IND) {
      page = !quoteId ? "/dashboard" : "/rodetails";
    } else if (appSource === appSources.ENG) {
      page = "/rodetails";
    }
  }
  return page;
}

/**
 * Takes the dealer and user objects as arguments and returns the stringified configuration object that Beamer expects.
 * @param {object} dealer
 * @param {object} user
 * @returns {string} beamerConfigurationObjectString
 */
export function createBeamerConfigurationObjectString(dealer, user) {
  const { dmsType } = dealer;
  const dmsParam = !dmsType ? "" : dmsType.toString().toLowerCase();
  const { trainingRole } = user;
  const roleParam = !trainingRole ? "" : trainingRole.toString().toLowerCase();
  const hostname = window.location.hostname;
  // localhost will be treated as quoting
  let appParam = APPLICATIONS.QUOTING;
  if (
    hostname.indexOf("csr.") !== -1 ||
    hostname.indexOf("servicero.") !== -1
  ) {
    appParam = APPLICATIONS.CSR;
  } else if (hostname.indexOf("servicequoting.") !== -1) {
    appParam = APPLICATIONS.QUOTING;
  }
  // Expected namespace values: prod, prodaus, dev6, qa6, stg4
  const namespace = xmmAxios.getQuotingNameSpace();
  const envParam = namespace.includes("prod") ? "prod" : "nonprod";
  return `var beamer_config =
    {
      product_id : 'YtpIRyvM33302',
      counter: true,
      alert : true,
      selector: '.beamerTrigger, #callBeamer',
      filter: 'app=${appParam};dms=${dmsParam};role=${roleParam};env=${envParam}'
    };
  `;
}

export const isDMSPlus = dmsType => dmsType?.toUpperCase() === "DMS+";
export const isDTDMS = dealerProperties =>
  (!dealerProperties?.ENABLE_CSR_APP ||
    dealerProperties?.ENABLE_CSR_APP === NO) &&
  (!dealerProperties?.SPI_SQS_ENABLED ||
    dealerProperties?.SPI_SQS_ENABLED === NO);
